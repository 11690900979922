.greyButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 44px;
    background: #F2F3F1;
    border-radius: 5px;
    border: none;
    font-family: 'Poppins-Medium';
    color: #3C6B49;
    cursor: pointer;
    margin: 5px 10px 5px 0px;
    width: 100%;
}

.greenButton {
    color: #FAFAFA;
    margin: 5px 10px 5px 0px;
    display: flex;
    border: none;
    cursor: pointer;
    font-family: 'Poppins-Medium';
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 44px;
    background: #3C6B49;
    border-radius: 5px;
    /* padding: 20px 50px; */
    width: 100%;
}

@media only screen and(max-width: 600px) {
    .greyButton,
    .greenButton {
        width: 120px;
        height: 30px;
        font-size: small;
        margin: 10px 4px;
    }
}
