body {
  margin: 0;
}
.swal2-container {
  z-index: 2000 !important;
}
.reload-button {
  display: flex;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-around;
  align-items: center;
  text-transform: capitalize;
  background: #3c6b49;
  border-radius: 5px;
  color: #fafafa;
  height: 38px;
  font-family: Poppins-SemiBold;
  font-size: 15px;
  cursor: pointer;
  border: none;
}
.swal2-popup {
  /* font-size: 1.6rem !important; */
  font-family: 'Poppins-Medium' !important;
}
.imageShadow {
  -webkit-filter: drop-shadow(3px 3px 3px #c3c3c3);
  filter: drop-shadow(3px 3px 3px #c3c3c3);
}
.PhoneInputInput {
  font-family: Poppins-Regular;
  height: 32px;
  color: #1e3625;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-left: none;
  border-top: none;
  padding-top: 18px !important;
  border-bottom: none;
  border-right: 1px solid #d8e1db;
  background-color: white;
}
.selectedTab {
  opacity: 1;
  color: #3c6b49;
  border-bottom: 4px solid #3c6b49;
}
.PhoneInput--focus {
  outline: none !important;
  border: 1px solid #1e3625 !important;
}
.PhoneInput {
  border: 1px solid #d8e1db;
  border-radius: 8px;
  padding-left: 10px;
}
.PhoneInputInput:focus {
  outline: none !important;
  border: none;
}
.col2signup {
  position: relative;
  height: 102.5vh;
}
.col2createAccount {
  position: relative;
  height: 110vh;
}
.col2signup > img,
.col2createAccount > img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.submitForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.AddnewCard {
  height: 120px;
}

.css-onb1p0-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-onb1p0-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.css-fmgwno-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-fmgwno-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #3c6b49 !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #487454 !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #3c6b49 !important;
}
.css-5xe99f-MuiLinearProgress-bar1 {
  color: #3c6b49 !important;
  background-color: #3c6b49;
}
.css-v7esy {
  margin-left: 0 !important;
}
.disableDays {
  border: 2px solid #cccccc !important;
  background-color: #3c6b49 !important;
}

.Calendar__sectionWrapper {
  min-height: 26em !important;
}
.DatePicker {
  width: 100% !important;
}
.MuiAutocomplete-input,
.MuiInputLabel-root {
  font-family: Poppins-Regular !important;
  font-size: small !important;
}
.Calendar__day.-disabled:hover {
  color: #111111 !important;
  border-width: 1.5px !important;
  border-color: #cccccc !important;
  border-radius: 50% !important;
  border-style: dashed !important;
}
.customDisableDays:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #111111 !important;
  opacity: 40% !important;
  border-radius: 50% !important;
  border: 1.5px dashed #111111 !important;
}
.customDisableDays {
  color: #111111 !important;
  opacity: 40% !important;
  border-radius: 50% !important;
  border: 1.5px dashed #111111 !important;
}
.Calendar__yearSelectorWrapper.-faded::after,
.Calendar__yearSelectorWrapper.-faded::before {
  z-index: 0 !important;
}
.Calendar__yearSelector {
  padding: 2px !important;
  overflow-y: auto !important;
}
.responsive-calendar {
  font-size: 9px !important; /* default to 10px */
  font-family: Poppins-Regular !important;
  color: #111111 !important;
}
.Calendar__yearSelector {
  overflow: auto !important;
}
.my-custom-input-class {
  height: 45px;
  color: #1e3625;
  border-color: #d8e1db;
  border-radius: 8px;
  background-color: '#FFFFFF';
}
.Calendar {
  --cl-color-disabled: rgb(168, 168, 168) !important;
  box-shadow: none !important;
  min-height: max-content !important;
}
.custom-today-day {
  color: white !important;
  background-color: #3c6b49 !important;
  text-transform: none !important;
  border-bottom: none !important;
}
.calendarSelectedDay {
  border-radius: 50% !important;
  color: white !important;
  background-color: #3c6b49 !important;
}
.calendarRangeStart,
.calendarRangeEnd,
.calendarRangeBetween {
  border-radius: 50% !important;
  color: white !important;
  background-color: #3c6b49 !important;
}
.Calendar__day.-disabled {
  color: #111111 !important;
  border-width: 1.5px !important;
  border-radius: 50% !important;
  border-style: dashed !important;
  opacity: 0.4 !important;
}
@font-face {
  font-family: 'PoppinsLight';
  src: local('PoppinsLight'), url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Black';
  src: local('Poppins-Black'), url('./assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

.filebutton {
  cursor: pointer;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'), url('./assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 17px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 17px;
  background: #d9d9d9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}
.notification-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  background: rgb(60, 107, 73, 0.5);
  font-size: 16px;
  color: white;
  z-index: 2;
  animation: slow 2s ease forwards;
  font-family: Poppins-Regular !important;
}
.btnFollow {
  background-color: unset;
  border: none;
}

.notification-banner-link {
  cursor: pointer;
  margin-left: 4px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  text-decoration: underline;
}
@media only screen and (max-width: 2000px) and (min-width: 1500px) {
  .header-left-logo {
    margin-left: -50px !important;
    margin-right: -20px !important;
  }
}

.ClearDates {
  font-family: 'Poppins-SemiBold';
  font-size: 0.9rem;
  color: #1e3625;
  background-color: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.7rem;
  margin-left: 0.3rem;
}
.dragndrop > label {
  min-width: unset;
  height: 135px;
  width: 100%;
  opacity: 0;
}
.css-zgth7f-MuiBadge-root .MuiBadge-badge {
  top: 15px !important;
  right: 15px !important;
}
.css-2flqd9-MuiBadge-root .MuiBadge-badge {
  background-color: #ed4c5c;
  top: 4px !important;
  color: #ed4c5c;
  right: 4px !important;
}
.Emojiwork > div {
  top: unset !important;
  left: 724px !important;
  bottom: 63px;
}

.closeemoji {
  width: 100%;
  display: flex;
  justify-content: end;
  cursor: pointer;
  margin-right: 1rem;
}
@media screen and (max-width: 1200px) {
  .css-eplvnu {
    width: unset !important;
  }
}
