.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Poppins';
    cursor: pointer;
    margin: 5px 0;
    color: #111111;
    opacity: 0.6;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
