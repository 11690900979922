.slider-custom {
    width: 70%;
    height: 100%;
    margin: auto
}
.SampleNextArrow{
    top: 44%;
    background-color: #3C6B49;
    position: absolute;
    right: -5px;
    width: 5%;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.SamplePrevArrow{
    top: 44%;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 30;
    width: 5%;
    height: 7vh;
        display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.slick-dots {
bottom: -60px !important;
}

.slick-dots li.slick-active button:before{
    width: 28px;
    margin-right: 22px;

  }
.slick-dots li button:before {
    background-color: #3C6B49;
    height: 10px;
    width: 12px;
    text-indent: -9999px;
    overflow:hidden; 
    border-radius: 20px;
    }
    .slick-dots li:nth-of-type(2) {
     margin-left: 22px;
        }
        .slick-dots li:nth-of-type(2){
            margin-right: 22px;
        }
        
    
@media only screen and (max-width: 800px) {
    .SampleNextArrow,.SamplePrevArrow{
        width: 9%;
        height: 6vh;
    }
    .slick-dots {
        bottom: auto !important;
        }
  }
@media only screen and (max-width: 750px) {
    .slider-custom {
        width: 100%;
    }
    .SampleNextArrow,.SamplePrevArrow{
        width: 8%;
        height: 6vh;
    }
  }
  @media only screen and (max-width: 600px) {
    .SampleNextArrow,.SamplePrevArrow{
        width: 8%;
        height: 6vh;
    }
  }
  @media only screen and (max-width: 400px) {
 
    .SampleNextArrow,.SamplePrevArrow{
        width: 10%;
        height: 5vh;
    }
  }