.desktopCrossButton {
  position: absolute;
  right: 10px;
  top: 20px;
}
@media only screen and (max-width: 600px) {
  .desktopCrossButton {
    display: none;
  }
}
