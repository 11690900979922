.selectedPlanBtn {
    color: #FFFFFF;
    background-color: #3C6B49;
    opacity: 1;
    font-family: 'Poppins-SemiBold';
    font-size: 0.9rem;
    font-style: normal;
    width: 78px;
    padding: 8px 10px;
    border: none;
}
.credit-card-input {
    position: relative !important;
    font-size: 0.8rem !important;
    border-radius: 8px !important;
    font-family: PoppinsLight !important;
    color: rgb(30, 54, 37) !important;
}
#field-wrapper input::placeholder {
    color: rgb(30, 54, 37) !important;
    opacity: .5 !important;
}
#field-wrapper label {
    margin-left: 0 !important;
}
#card-number {
    width: auto !important;
}
#field-wrapper {
    border-radius: 8px !important;
    border: 1px solid #D8E1DB !important;

}
#field-wrapper img {
    display: none;
}
#field-wrapper input {
    border-radius: 8px !important;
    /* color: rgb(30, 54, 37) !important;
    opacity: .5 !important; */
}
.card-input img {
    display: none !important;
}
.notSelectedPlanBtn {
    background: #F4F4F4;
    border: 1px solid #DEDEDE;
    font-family: 'Poppins-SemiBold';
    font-size: 0.9rem;
    font-style: normal;
    width: 78px;
    padding: 8px 10px;
    color: #111111;
    opacity: 0.6;
}
.leftBtn {
    font-family: 'Poppins-SemiBold';
    border-radius: 8px 0 0 8px;
    cursor: pointer;
}
.rightBtn {
    font-family: 'Poppins-SemiBold';
    border-radius: 0 10px 10px 0;
    cursor: pointer;
}
.buttonDiv {
    width: 14vw;
}
@media only screen and (max-width: 900px) {
    .buttonDiv {
        width: 30vw;
    }
}
@media only screen and (max-width: 600px) {
    .buttonDiv {
        width: 45%;
    }
}
