.selectedPlanBtn {
  color: #ffffff;
  background-color: #3c6b49;
  opacity: 1;
  font-family: 'Poppins-SemiBold';
  font-size: 0.9rem;
  font-style: normal;
  width: 78px;
  padding: 8px 10px;
  border: none;
}
.credit-card-input {
  position: relative !important;
  font-size: 0.8rem !important;
  border-radius: 8px !important;
  font-family: PoppinsLight !important;
  color: rgb(30, 54, 37) !important;
}
#field-wrapper input::placeholder {
  color: rgb(30, 54, 37) !important;
  opacity: 0.5 !important;
}
#field-wrapper label {
  margin-left: 0 !important;
}
#card-number {
  width: auto !important;
}
#field-wrapper {
  border-radius: 8px !important;
  border: 1px solid #d8e1db !important;
}
#field-wrapper img {
  display: none;
}
#field-wrapper input {
  border-radius: 8px !important;
  /* color: rgb(30, 54, 37) !important;
    opacity: .5 !important; */
}
.card-input img {
  display: none !important;
}
.notSelectedPlanBtn {
  background: #f4f4f4;
  border: 1px solid #dedede;
  font-family: 'Poppins-SemiBold';
  font-size: 0.9rem;
  font-style: normal;
  width: 78px;
  padding: 8px 10px;
  color: #111111;
  opacity: 0.6;
}
.leftBtn {
  font-family: 'Poppins-SemiBold';
  border-radius: 8px 0 0 8px;
  cursor: pointer;
}
.rightBtn {
  font-family: 'Poppins-SemiBold';
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}
.buttonDiv {
  width: 14vw;
}

.manageCardDateAndName {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.deleteBtn > P {
  color: #b93b3b;
  text-decoration: underline;
  font-weight: 600;
  padding-right: 1rem;
  cursor: pointer;
  font-size: 0.8rem;

}
.greenBtn > P {
  color: #283c2e;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 16px;
  padding-right: 1rem;
  cursor: pointer;
  font-size: 0.8rem;
}
.EditContainer {
  margin: 0rem 0 1rem 1rem;
}
button.swal2-deny.swal2-styled.swal2-default-outline {
  background-color: #b93b3b !important;
}
.centerDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.9rem;
  text-align: center;
}
.UserInput {
  width: 95%;
}

.sc-bcXHqe.cFpCkR {
  width: -webkit-fill-available;
}
.gpNZNw input#cardNumber {
  width: 100% !important;
}
.dyGJUc input#cardNumber {
  width: 100% !important;
}
.gyQPZE input#cardNumber {
  width: 100% !important;
}
.dMkwas input#cardNumber {
  width: 100% !important;
}
@media only screen and (max-width: 900px) {
  .buttonDiv {
    width: 30vw;
  }
}
@media only screen and (max-width: 600px) {
  .buttonDiv {
    width: 45%;
  }
}
