.greyButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 144px;
    height: 44px;
    background: #F2F3F1;
    border-radius: 5px;
    border: none;
    font-family: 'Poppins';
    color: #3C6B49;
    cursor: pointer;
    margin: 5px 0;

}
.greenButton {
    color: #FAFAFA;
    margin: 5px 0;
    display: flex;
    border: none;
    cursor: pointer;
    font-family: 'Poppins';
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 144px;
    gap: 10px;
    height: 44px;
    background: #3C6B49;
    border-radius: 5px;
}
.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Poppins';
    cursor: pointer;
    margin: 5px 0;
    color: #111111;
    opacity: 0.6;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.selectedTab {
    opacity: 1;
    color: #3C6B49;
    border-bottom: 4px solid #3C6B49;
}
.avatar-upload {
    position: relative;
}
.avatar-upload .avatar-edit {
    position: absolute;
    right: -5px;
    z-index: 1;
    bottom: 16px;
}
.cover-edit {
    text-align: right;
    margin-right: 20px;
    position: relative;
    z-index: 1;
}
.avatar-upload .avatar-edit input,
.cover-edit input,
.identity-upload input {
    display: none;
}
.cover-edit label {
    display: block;
}
.avatar-upload .avatar-edit input + label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #3C6B49;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
    background: #3C6B49;
    border-color: white;
}
.imgParent {
    position: relative;
    display: inline-block;
    width: 200px;
    padding-right: 5px;
    padding-bottom: 5px;
    height: 150px;
}
.imgParent img {
    height: 100%;
    max-width: 100%;
    object-fit: fill;
    border-radius: 5px;
}
.allimageParent {
    position: relative;
    display: inline-block;
    width: 200px;
    padding-right: 5px;
    padding-bottom: 5px;
    height: 140px;
}
.allimageParent img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;

}
.uploadImageModal {
    position: relative;
    display: inline-block;
    width: 300px;
    padding-right: 5px;
    padding-bottom: 5px;
    height: 140px;
}
.uploadImageModal img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;

}
.editImages {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 3px
}
.editImagesAddBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.editImages img {
    max-width: 100%;
    max-height: 100%;
}
.uploadedImages {
    width: 80px;
    height: 80px;
    padding: 3px
}
.uploadedImages img {
    height: 100%;
    max-width: 100%;
    object-fit: fill;
}
.closeeditImages {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #EEEEEE;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.closeallimageParent {
    position: absolute;
    top: 8px;
    right: 13px;
    background: #EEEEEE;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 18px;
    background: #EEEEEE;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .greyButton,
    .greenButton {
        width: 120px;
        height: 30px;
        font-size: small;
        margin: 10px 4px;
    }
    .selectedTab {
        font-size: small;
    }
    .tab {
        font-size: small;
    }
}
