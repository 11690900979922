.green-link {
    color: #3C6B49;
    font-size: 14px;
    margin-left: 4px;
    font-family: 'Poppins-Regular';
}

.message {
    margin-top: 0px;
    font-size: 14px;
    color: #101B10;
    margin-bottom: 2px;
    font-family: 'Poppins-Regular';
}

.first-link{
    color: #3C6B49;
    font-size: 14px;
    margin-right: 4px;
    font-family: 'Poppins-Regular';
}