.subscribeAlert {
    background-color: #EEFAF1;
    padding: 20px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.subscribeAlert > span{
    font-family: 'Poppins-Regular';
    font-size: 14px;
}

.subscribeAlert > span > a{
    font-family: 'Poppins-SemiBold' !important;
    font-size: 14px;
    color: #3C6B49 !important;
}
