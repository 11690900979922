.meter {
    box-sizing: content-box;
    height: 20px; 
    position: relative;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    width: 100%;
    background-color:#DDDDDD;
    border-radius: 3px;
}
.meter > span {
    display: block;
    height: 100%;
    border-radius: 3px;
    background-color: #3D8551;
    background-image: linear-gradient(center bottom, #3D8551 30%, #3D8551 60%);
    position: relative;
    overflow: hidden;
}
.meter > span:after,
.animate > span > span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(45deg, #569C69 10%, transparent 25%, transparent 50%, #569C69 50%, #569C69 75%, transparent 75%, transparent);
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    overflow: hidden;
}
